<template>
  <div class="container">
    <div class="is-size-4">Konto administratora</div>
  </div>
</template>

<script>
export default {
  name: "AdminAccount",
};
</script>

<style>
</style>